// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-by-the-numbers-china-tsx": () => import("./../src/pages/by-the-numbers/china.tsx" /* webpackChunkName: "component---src-pages-by-the-numbers-china-tsx" */),
  "component---src-pages-by-the-numbers-india-tsx": () => import("./../src/pages/by-the-numbers/india.tsx" /* webpackChunkName: "component---src-pages-by-the-numbers-india-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-customer-stories-tsx": () => import("./../src/pages/customer-stories.tsx" /* webpackChunkName: "component---src-pages-customer-stories-tsx" */),
  "component---src-pages-ebook-edge-cloud-media-entertainment-assessment-tsx": () => import("./../src/pages/ebook/edge-cloud-media-entertainment/assessment.tsx" /* webpackChunkName: "component---src-pages-ebook-edge-cloud-media-entertainment-assessment-tsx" */),
  "component---src-pages-ebook-edge-cloud-media-entertainment-index-tsx": () => import("./../src/pages/ebook/edge-cloud-media-entertainment/index.tsx" /* webpackChunkName: "component---src-pages-ebook-edge-cloud-media-entertainment-index-tsx" */),
  "component---src-pages-ebook-edge-cloud-media-entertainment-infographic-tsx": () => import("./../src/pages/ebook/edge-cloud-media-entertainment/infographic.tsx" /* webpackChunkName: "component---src-pages-ebook-edge-cloud-media-entertainment-infographic-tsx" */),
  "component---src-pages-ebook-game-tsx": () => import("./../src/pages/ebook/game.tsx" /* webpackChunkName: "component---src-pages-ebook-game-tsx" */),
  "component---src-pages-en-accelerate-2021-tsx": () => import("./../src/pages-en/accelerate2021.tsx" /* webpackChunkName: "component---src-pages-en-accelerate-2021-tsx" */),
  "component---src-pages-en-customer-story-ad-timing-tsx": () => import("./../src/pages-en/customer-story/AdTiming.tsx" /* webpackChunkName: "component---src-pages-en-customer-story-ad-timing-tsx" */),
  "component---src-pages-en-events-nanog-86-tsx": () => import("./../src/pages-en/events/nanog-86.tsx" /* webpackChunkName: "component---src-pages-en-events-nanog-86-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../src/pages-en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-lp-bare-metal-cloud-tsx": () => import("./../src/pages-en/lp/bare-metal-cloud.tsx" /* webpackChunkName: "component---src-pages-en-lp-bare-metal-cloud-tsx" */),
  "component---src-pages-en-lp-baremetal-tsx": () => import("./../src/pages-en/lp/baremetal.tsx" /* webpackChunkName: "component---src-pages-en-lp-baremetal-tsx" */),
  "component---src-pages-en-lp-cloud-networking-tsx": () => import("./../src/pages-en/lp/cloud-networking.tsx" /* webpackChunkName: "component---src-pages-en-lp-cloud-networking-tsx" */),
  "component---src-pages-en-lp-content-delivery-network-tsx": () => import("./../src/pages-en/lp/content-delivery-network.tsx" /* webpackChunkName: "component---src-pages-en-lp-content-delivery-network-tsx" */),
  "component---src-pages-en-lp-intel-whitepaper-tsx": () => import("./../src/pages-en/lp/intel-whitepaper.tsx" /* webpackChunkName: "component---src-pages-en-lp-intel-whitepaper-tsx" */),
  "component---src-pages-en-lp-products-zga-intel-tsx": () => import("./../src/pages-en/lp/products-zga-intel.tsx" /* webpackChunkName: "component---src-pages-en-lp-products-zga-intel-tsx" */),
  "component---src-pages-en-lp-products-zga-tsx": () => import("./../src/pages-en/lp/products-zga.tsx" /* webpackChunkName: "component---src-pages-en-lp-products-zga-tsx" */),
  "component---src-pages-en-lp-thanks-tsx": () => import("./../src/pages-en/lp/thanks.tsx" /* webpackChunkName: "component---src-pages-en-lp-thanks-tsx" */),
  "component---src-pages-en-lp-vpn-providers-tsx": () => import("./../src/pages-en/lp/vpn-providers.tsx" /* webpackChunkName: "component---src-pages-en-lp-vpn-providers-tsx" */),
  "component---src-pages-en-lp-vpn-tsx": () => import("./../src/pages-en/lp/vpn.tsx" /* webpackChunkName: "component---src-pages-en-lp-vpn-tsx" */),
  "component---src-pages-en-lp-zenlayer-global-accelerator-intel-tsx": () => import("./../src/pages-en/lp/zenlayer-global-accelerator-intel.tsx" /* webpackChunkName: "component---src-pages-en-lp-zenlayer-global-accelerator-intel-tsx" */),
  "component---src-pages-en-lp-zenlayer-global-accelerator-tsx": () => import("./../src/pages-en/lp/zenlayer-global-accelerator.tsx" /* webpackChunkName: "component---src-pages-en-lp-zenlayer-global-accelerator-tsx" */),
  "component---src-pages-en-service-level-agreements-tsx": () => import("./../src/pages-en/service-level-agreements.tsx" /* webpackChunkName: "component---src-pages-en-service-level-agreements-tsx" */),
  "component---src-pages-en-turbo-icons-tsx": () => import("./../src/pages-en/turbo-icons.tsx" /* webpackChunkName: "component---src-pages-en-turbo-icons-tsx" */),
  "component---src-pages-en-turbo-tsx": () => import("./../src/pages-en/turbo.tsx" /* webpackChunkName: "component---src-pages-en-turbo-tsx" */),
  "component---src-pages-en-what-is-new-tsx": () => import("./../src/pages-en/what-is-new.tsx" /* webpackChunkName: "component---src-pages-en-what-is-new-tsx" */),
  "component---src-pages-ethics-and-compliance-tsx": () => import("./../src/pages/ethics-and-compliance.tsx" /* webpackChunkName: "component---src-pages-ethics-and-compliance-tsx" */),
  "component---src-pages-gameeq-tsx": () => import("./../src/pages/gameeq.tsx" /* webpackChunkName: "component---src-pages-gameeq-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-new-index-tsx": () => import("./../src/pages/newIndex.tsx" /* webpackChunkName: "component---src-pages-new-index-tsx" */),
  "component---src-pages-partners-channel-partner-tsx": () => import("./../src/pages/partners/channel-partner.tsx" /* webpackChunkName: "component---src-pages-partners-channel-partner-tsx" */),
  "component---src-pages-partners-reseller-partner-tsx": () => import("./../src/pages/partners/reseller-partner.tsx" /* webpackChunkName: "component---src-pages-partners-reseller-partner-tsx" */),
  "component---src-pages-partners-solutions-partner-tsx": () => import("./../src/pages/partners/solutions-partner.tsx" /* webpackChunkName: "component---src-pages-partners-solutions-partner-tsx" */),
  "component---src-pages-products-bare-metal-cloud-tsx": () => import("./../src/pages/products/bare-metal-cloud.tsx" /* webpackChunkName: "component---src-pages-products-bare-metal-cloud-tsx" */),
  "component---src-pages-products-cloud-networking-tsx": () => import("./../src/pages/products/cloud-networking.tsx" /* webpackChunkName: "component---src-pages-products-cloud-networking-tsx" */),
  "component---src-pages-products-cloud-networking-zenlayer-cloud-networking-white-paper-tsx": () => import("./../src/pages/products/cloud-networking/zenlayer-cloud-networking-white-paper.tsx" /* webpackChunkName: "component---src-pages-products-cloud-networking-zenlayer-cloud-networking-white-paper-tsx" */),
  "component---src-pages-products-cloud-wan-tsx": () => import("./../src/pages/products/cloud-wan.tsx" /* webpackChunkName: "component---src-pages-products-cloud-wan-tsx" */),
  "component---src-pages-products-content-delivery-network-tsx": () => import("./../src/pages/products/content-delivery-network.tsx" /* webpackChunkName: "component---src-pages-products-content-delivery-network-tsx" */),
  "component---src-pages-products-edge-data-center-services-tsx": () => import("./../src/pages/products/edge-data-center-services.tsx" /* webpackChunkName: "component---src-pages-products-edge-data-center-services-tsx" */),
  "component---src-pages-products-ip-transit-tsx": () => import("./../src/pages/products/ip-transit.tsx" /* webpackChunkName: "component---src-pages-products-ip-transit-tsx" */),
  "component---src-pages-products-professional-services-tsx": () => import("./../src/pages/products/professional-services.tsx" /* webpackChunkName: "component---src-pages-products-professional-services-tsx" */),
  "component---src-pages-products-virtual-machine-tsx": () => import("./../src/pages/products/virtualMachine.tsx" /* webpackChunkName: "component---src-pages-products-virtual-machine-tsx" */),
  "component---src-pages-products-zenlayer-global-accelerator-tsx": () => import("./../src/pages/products/zenlayer-global-accelerator.tsx" /* webpackChunkName: "component---src-pages-products-zenlayer-global-accelerator-tsx" */),
  "component---src-pages-products-zenlayer-global-accelerator-zenlayer-global-accelerator-white-paper-tsx": () => import("./../src/pages/products/zenlayer-global-accelerator/zenlayer-global-accelerator-white-paper.tsx" /* webpackChunkName: "component---src-pages-products-zenlayer-global-accelerator-zenlayer-global-accelerator-white-paper-tsx" */),
  "component---src-pages-solutions-blockchain-tsx": () => import("./../src/pages/solutions/blockchain.tsx" /* webpackChunkName: "component---src-pages-solutions-blockchain-tsx" */),
  "component---src-pages-solutions-china-tsx": () => import("./../src/pages/solutions/china.tsx" /* webpackChunkName: "component---src-pages-solutions-china-tsx" */),
  "component---src-pages-solutions-cloud-service-providers-tsx": () => import("./../src/pages/solutions/cloud-service-providers.tsx" /* webpackChunkName: "component---src-pages-solutions-cloud-service-providers-tsx" */),
  "component---src-pages-solutions-gaming-tsx": () => import("./../src/pages/solutions/gaming.tsx" /* webpackChunkName: "component---src-pages-solutions-gaming-tsx" */),
  "component---src-pages-solutions-hybrid-cloud-tsx": () => import("./../src/pages/solutions/hybrid-cloud.tsx" /* webpackChunkName: "component---src-pages-solutions-hybrid-cloud-tsx" */),
  "component---src-pages-solutions-india-tsx": () => import("./../src/pages/solutions/india.tsx" /* webpackChunkName: "component---src-pages-solutions-india-tsx" */),
  "component---src-pages-solutions-media-entertainment-tsx": () => import("./../src/pages/solutions/media-entertainment.tsx" /* webpackChunkName: "component---src-pages-solutions-media-entertainment-tsx" */),
  "component---src-pages-solutions-southeast-asia-tsx": () => import("./../src/pages/solutions/southeast-asia.tsx" /* webpackChunkName: "component---src-pages-solutions-southeast-asia-tsx" */),
  "component---src-pages-solutions-vpn-tsx": () => import("./../src/pages/solutions/vpn.tsx" /* webpackChunkName: "component---src-pages-solutions-vpn-tsx" */),
  "component---src-pages-support-tsx": () => import("./../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-widget-footer-tsx": () => import("./../src/pages/widget/footer.tsx" /* webpackChunkName: "component---src-pages-widget-footer-tsx" */),
  "component---src-pages-widget-speed-test-tsx": () => import("./../src/pages/widget/speedTest.tsx" /* webpackChunkName: "component---src-pages-widget-speed-test-tsx" */),
  "component---src-pages-zenlayer-insider-tsx": () => import("./../src/pages/zenlayer-insider.tsx" /* webpackChunkName: "component---src-pages-zenlayer-insider-tsx" */),
  "component---src-templates-blog-singular-tsx": () => import("./../src/templates/blog-singular.tsx" /* webpackChunkName: "component---src-templates-blog-singular-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-customer-story-index-tsx": () => import("./../src/templates/customer-story/index.tsx" /* webpackChunkName: "component---src-templates-customer-story-index-tsx" */),
  "component---src-templates-documentation-tsx": () => import("./../src/templates/documentation.tsx" /* webpackChunkName: "component---src-templates-documentation-tsx" */),
  "component---src-templates-event-singular-tsx": () => import("./../src/templates/event-singular.tsx" /* webpackChunkName: "component---src-templates-event-singular-tsx" */),
  "component---src-templates-event-tsx": () => import("./../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-press-tsx": () => import("./../src/templates/press.tsx" /* webpackChunkName: "component---src-templates-press-tsx" */),
  "component---src-templates-what-is-new-article-tsx": () => import("./../src/templates/what-is-new-article.tsx" /* webpackChunkName: "component---src-templates-what-is-new-article-tsx" */)
}

